import { FaDiscord } from "react-icons/fa";
import {AiOutlineTwitter} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {FaMediumM} from 'react-icons/fa';
export const nav = [
  {
    name: "Roadmap",
    path: "#roadmap",
  },
  {
    name: "About Us",
    path: "#about",
  },
  {
    name: "Our Team",
    path: "#team",
  },
  {
    name: "FAQs",
    path: "#faq",
  },
];

export const team = [
  {
    name: "DEVEN COLLINS AKA NOTSICK",
    position: "FOUNDER & LEAD OF DEVELOPMENT",
    path: "/images/deven.png",
    desc: "MY NAME IS DEVEN COLLINS AND I AM FROM BRITISH COLUMBIA, CANADA. I STARTED INVESTING IN CRYPTO AROUND LATE 2016. LEARNED HOW TO OPTION TRADE AND IT BECAME MY JOB IN EARLY 2018 AND I LOVE EVERY MINUTE OF IT. I GOT FULLY INTO NFTS RIGHT AFTER CRYPTOPUNKS WAS RELEASED. I HAVE BEEN PLANNING THIS PROJECT FOR ABOUT SEVEN MONTHS AND CANT WAIT TO SHARE IT ALL :)",
  },
  {
    name: "HARIS REIS",
    position: "LEAD OF MARKETING",
    path: "/images/haris.png",
    desc: "HARIS REIS HAS 14 YEARS IN THE ENTREPRENEUR SPACE. BUILDING EVERYTHING FROM A PUBLISHING COMPANY TO A SAAS SERVICE BEING USED IN 36 COUNTRIES. HE'S WORKED WITH THE LIKES OF GARYVEE, MANY CELEBRITIES AND BIG NAME ENTREPRENEURS BUILDING AND MONETIZING THEIR BRANDS AND IS NOW FINDING WAYS TO INNOVATE WEB3.",
  },
  {
    name: "FAIZEL",
    position: "BACK END DEVELOPER & TOKENOMICS",
    path: "/images/Faziel.png",
    desc: "FAIZEL HAS DEVELOPED MODERN, SECURE, AND SCALABLE COMPLEX APPLICATIONS RELATED TO CRYPTO TOKENOMICS, BLOCKCHAIN CONSENSUS PROTOCOLS, P2P NETWORKS, CRYPTOGRAPHIC PRINCIPLES, ENCRYPTION MANAGEMENT, AND ALGORITHMIC BACK-ENDS.",
  },
];

export const roadmap = [
  {
    id: "01",
    points: [
      "Marketing to build a solid foundation and community to ensure long-term support",
      "Expansion of marketing on different platforms such as YouTube, Instagram, and Twitch",
    ],
  },
  {
    id: "02",
    points: [
      "The mint of 6,100 Royal Society NFT's",
      "Listing on Magic Eden",
      "Pre-approval for Moonrank and Solrarity",
    ],
  },
  {
    id: "03",
    points: [
      "Royal Society DeFi map extension",
      "Tavern (casino) all profits will go to the Royal Society Liquidity Pool",
    ],
  },
  {
    id: "04",
    points: [
      "Royal Society DeFi map extension",
      "Tavern (casino) all profits will go to the Royal Society Liquidity Pool",
    ],
  },
  {
    id: "05",
    points: [
      "Royal Society DeFi map extension",
      "Tavern (casino) all profits will go to the Royal Society Liquidity Pool",
    ],
  },
  {
    id: "06",
    points: [
      "Royal Society DeFi map extension",
      "Tavern (casino) all profits will go to the Royal Society Liquidity Pool",
    ],
  },
];

export const FAQ = [
  {
    q: "Whitelist?",
    ans: "Yes we will have three stages Royal Table, Royal Hall, and Great Hall. Royal Table will have 700 spots with 3 mints per wallet. Royal Hall will have 1,200 spots with 2 mints per wallet. Great Hall will have 1,500 spots and 1 mint per wallet.",
  },
  {
    q: "What is the future for Royal Society?",
    ans: "Royal Society's goal is to have $150 million locked in the Liquidation Pool within 1-2 years. For comparison, DeFi Kingdoms has just surpassed $205 million dollars locked into their Liquidation Pool. They’ve been in the marketplace for 10 months. ",
    sec: " Royal Society would be integrating Bluechip Projects to our DeFi platform for Token Staking.",
    list: [
      "80-90k Discord Members",
      "120-135k For Twitter.",
      "10-20 BlueChip Partner Integrations (ETH/SOL/ADA/NEAR).",
      "250-350k Active Users on our DeFi Platform.      ",
      "BlueChip Recognition.",
    ],
  },
  {
    q: "What is $SWRD?",
    ans: "$SWRD is our native token and will have a large array of utilities. $SWRD is a liquidity output, it can be swapped for USDC.",
  },
  {
    q: "Wen DeFi platform?",
    ans: "The Royal Society demo DeFi platform will be released before mint!",
  },
];

export const collection = [
  {
    name: "Echo",
    path: "/images/nft/1.mp4",
    qty: "5000",
  },
  {
    name: "Echo",
    path: "/images/nft/2.mp4",
    qty: "5000",
  },
  {
    name: "Echo",
    path: "/images/nft/3.mp4",
    qty: "5000",
  },
  {
    name: "Echo",
    path: "/images/nft/4.mp4",
    qty: "5000",
  },
  {
    name: "Echo",
    path: "/images/nft/5.mp4",
    qty: "5000",
  },
  {
    name: "Echo",
    path: "/images/nft/6.mp4",
    qty: "5000",
  },
  {
    name: "Echo",
    path: "/images/nft/7.mp4",
    qty: "5000",
  },
];
export const links = [
  {
    name: "twitter",
    link: "https://twitter.com/Diva4EVR_NFT",
    svg: <AiOutlineTwitter />,
    src: "/images/twitter-png.webp",
  },
  {
    name: "discord",
    link: "https://discord.gg/Jr83NYXSAR",
    svg: <FaDiscord />,
    src: "/images/discord-png.webp",
  },
 
];
