import React, { useState, useEffect } from "react";
import "./scss/normalize.css";
import Banner from "./components/Banner";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import Roadmap from "./components/Roadmap";
import Loader from "./components/Loader";
import Teams from "./components/Teams";
import "./scss/reset.css";
import AboutNft from "./components/AboutNft";
import ClipLoader from "react-spinners/ClipLoader";
import Collection from "./components/Collection";
import { Scrollbar } from "smooth-scrollbar-react";
import CustomForm from './components/Mail'
var Spinner = require("react-spinkit");

function App() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffb800");
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Loader setLoading={setLoading} />
      ) : (
        <>
          <main style={{ overflow: "hidden" }}>
            <Banner />
            <AboutNft />
          
        <Footer/>
          </main>
        </>
      )}
    </div>
  );
}

export default App;
