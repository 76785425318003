import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://gmail.us8.list-manage.com/subscribe/post?u=07077afb02534dbb1b599bd4f&amp;id=2c8db77361&amp;f_id=00e603e0f0";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />

// use the render prop and your custom for m
const styling = {
  color: 'green',
  backgroundImage: '-webkit-linear-gradient(top, #efd100, #e2a233 19%, #f0c328 30%, #fff1a3 43%, #ffe13e 50%, #fff 51%, #fff)',
  backgroundSize: '1em 2.5em',
  color: '#f8b700',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  position: 'absolute',
  bottom: '15px'
}
export const CustomForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <SimpleForm onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && <div className="secondry" style={styling}>sending...</div>}
        {status === "error" && <div className="secondry" style={styling} dangerouslySetInnerHTML={{ __html: message }} />}
        {status === "success" && <div className="secondry" style={styling}>Subscribed !</div>}
      </div>
    )}
  />
)

export default CustomForm;