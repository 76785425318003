import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/banner.scss";
import vid from "./banner.mp4";
import Navigation from "./Navigation";
import { BannerImages } from "../assests/data";

const Banner = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <Navigation />
      <section className="banner">
        <video
          src={vid}
          width="100%"
          height="360"
          controls={false}
          loop={true}
          autoPlay={true}
          muted={true}
          playsInline={true}
        ></video>
        <div className="container">
          <div className="item">
            <h1>Coming Soon!</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
