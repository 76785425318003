import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/aboutnft.scss";
import CustomForm from "./Mail";

const AboutNft = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  }, []);

  return (
    <>
      <section id="about" className="about">
        <span></span>
        <p></p>
        <div className="container">
          <div className="about__grid">
            <div className="email__outer" data-aos={'fade-up'}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <h1>Subscribe For More Details</h1>
              <CustomForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutNft;
