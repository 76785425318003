import React from "react";
import "../scss/footer.scss";
import {FaMediumM} from 'react-icons/fa';
import { FaDiscord } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="grid">
          {/* <a href="">
            <div className="logo">
              <img src="/images/logo.png" alt="" />
            </div>
          </a> */}
          <div className="icons">
            <div className="item">
              <a href="https://discord.gg/8pSgNfqS" target="_blank">
                <FaDiscord />
              </a>
            </div>
            <div className="item">
              <a href="https://instagram.com/warminerNFT" target="_blank">
                <AiFillInstagram />
              </a>
            </div>
            <div className="item">
              <a href="https://twitter.com/warminerNFT" target="_blank">
                <AiOutlineTwitter />
              </a>
            </div>
            <div className="item">
              <a href="https://medium.com/@warminer" target="_blank">
                <FaMediumM />
              </a>
            </div>
          </div>
          {/* <p> ⒸCopyright 2022 - All Rights Are Reserved </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
